<template>
    <div>
        <div v-if="baustein.id_baustein_typ === 1">
            <emelder-melden-bs1-anzeige-text
                :grid_col='grid_col'
                :baustein='baustein'
            >
            </emelder-melden-bs1-anzeige-text>
        </div>
        <div v-else-if="baustein.id_baustein_typ === 2">
            <emelder-melden-bs2-eingabe-text-kurz
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs2-eingabe-text-kurz>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===3">
            <emelder-melden-bs3-eingabe-text-lang
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs3-eingabe-text-lang>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===4">
            <emelder-melden-bs4-eingabe-text-nummerisch
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs4-eingabe-text-nummerisch>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===5">
            <emelder-melden-bs5-eingabe-datum
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs5-eingabe-datum>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===6">
            <emelder-melden-bs6-eingabe-zeit
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs6-eingabe-zeit>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===7">
            <emelder-melden-bs7-org-elemente
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
                :api_org_element_gruppen_in="api_org_element_gruppen_in"
                :api_org_elemente_in="api_org_elemente_in"
            >
                <!-- // api_org_element_gruppen_in="{{App\Helpers\SignedRoute::create('emelder.melden.api_get_org_element_gruppen')}}"
                // api_org_elemente_in="{{App\Helpers\SignedRoute::create('emelder.melden.api_get_org_elemente', ['id_org_element_gruppe' => isset($org_element) ? $org_element : 1])}}"-->
            </emelder-melden-bs7-org-elemente>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===8">
            <emelder-melden-bs8-datei-upload
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs8-datei-upload>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===9">
            <emelder-melden-bs9-globale-auswahlliste
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
                :api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in="api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in"
            >
                <!--   @if(isset($options))  :options_in='{{isset($options) ? json_encode($options) : ''}}' @endif-->
            </emelder-melden-bs9-globale-auswahlliste>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===10">
            <emelder-melden-bs10-globale-auswahlskala
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
                :api_einstellungen_meldungsvorlagen_layout_get_auswahlskala_in="api_einstellungen_meldungsvorlagen_layout_get_auswahlskala_in"
            >
            </emelder-melden-bs10-globale-auswahlskala>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===11">
            <emelder-melden-bs11-kontroll-kaestchen
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
                :api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in="api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in"
            >
            </emelder-melden-bs11-kontroll-kaestchen>
        </div>
        <div v-else-if="baustein.id_baustein_typ ===12">
            <emelder-melden-bs12-optische-trennlinie
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs12-optische-trennlinie>
        </div>
        <div v-else-if="baustein.id_baustein_typ === 13">
            <emelder-melden-bs13-betreff
                :grid_col='grid_col'
                :baustein='baustein'
                :input_in='input_in'
                :disabled_in="disabled_in"
            >
            </emelder-melden-bs13-betreff>
        </div>
    </div>
</template>
<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../vue-translations.js';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    import VueSweetalert2 from 'vue-sweetalert2';
    import BootstrapVue from 'bootstrap-vue';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    //Vue.use(VueSweetalert2);
    //Vue.use(BootstrapVue);

    export default {
        props: ['grid_col','input_in','disabled_in','api_org_element_gruppen_in','api_org_elemente_in','api_einstellungen_meldungsvorlagen_layout_get_auswahlliste_in','api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in'],
        components: {},
        data() {
            return {
                lang: null,
                baustein: this.grid_col['baustein'],
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {}
    };
</script>
<style scoped>
</style>
