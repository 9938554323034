<template>
    <div>
        <b-button class="btn btn-primary" variant="primary" v-b-modal.modal-1>Bearbeitung delegieren</b-button>
        <b-modal
            id="modal-1"
            title="Bearbeitung delegieren"
            centered
            @ok="action_delegation()"
            @show="resetModalRedakteur()"
            modal-ok="Bearbeitung Delegieren"
        >
            <div class="row mb-30">
                <div class="col-md-12">
                    <div class="form-group d-inline-block">
                        <label class="required">Auswahl</label>
                        <treeselect
                            name="aenderung_durch_sonstige"
                            :multiple="multiple"
                            :clearable="clearable"
                            :searchable="searchable"
                            :searchNested="searchNested"
                            :async="async"
                            :disabled="disabled"
                            :open-on-click="openOnClick"
                            :open-on-focus="openOnFocus"
                            :clear-on-select="clearOnSelect"
                            :close-on-select="closeOnSelect"
                            :always-open="alwaysOpen"
                            :append-to-body="appendToBody"
                            :options="options"
                            :value-consists-of="valueConsistsOf"
                            :loading="loading"
                            :auto-load-root-options="auto_load_root_options"
                            :load-options="loadOptions"
                            :show-count="showCount"
                            :showCountOf="showCountOf"
                            :loadingText="loadingText"
                            :noChildrenText="noChildrenText"
                            :noOptionsText="noOptionsText"
                            :noResultsText="noResultsText"
                            :openDirection="openDirection"
                            :placeholder="placeholder"
                            :required="required"
                            :limit="limit"
                            :max-height="maxHeight"
                            :disableFuzzyMatching="disableFuzzyMatching"
                            v-model="aenderung_durch_sonstige"
                            :value-format="valueFormat"
                            :normalizer="normalizer"
                            @select="onSelect()"
                            @deselect="onDeselect()"
                            @input="onInput()"
                            @open="onOpen()"
                        ></treeselect>
                    </div>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-md-12">
                    <label for="aenderung_aufgabe" class="required">Kommentar</label>
                    <b-form-textarea
                        id="aenderung_aufgabe"
                        v-model="aenderung_aufgabe"
                        placeholder="Anmerkungen zur Bearbeitung"
                        rows="5"
                        max-rows="10"
                    ></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                    Abbrechen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Bearbeitung delegieren
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

// import the component
import Treeselect from '@riophae/vue-treeselect'
import {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect';
import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'

// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
    props: ['meldung_id', 'signed_api_get_mitarbeiter_route_in', 'signed_api_post_pruefung_aenderung_route_in', 'signed_back_to_aufgaben_in', 'eigene_in'],
    name: "EMelderComponentMeldungenDelegieren",
    display: "EMelderComponentMeldungenDelegieren",
    order: 0,
    components: {
        Treeselect,
    },
    data() {
        return {
            collapsed: true,
            neuer_mitarbeiter_typ: null,
            aenderung_aufgabe: null,
            aenderung_durch_sonstige: null,

            // Switch Parameter
            multiple: false,
            disableFuzzyMatching: true,
            include_ids: null,
            exclude_ids: null,
            clearable: true,
            searchable: true,
            searchNested: false,
            async: false,
            disabled: false,
            openOnClick: true,
            openOnFocus: false,
            clearOnSelect: true,
            closeOnSelect: false,
            alwaysOpen: false,
            appendToBody: false,
            valueConsistsOf: 'LEAF_PRIORITY',
            loadingText: 'Lade...',
            noChildrenText: 'Keine Mitarbeiter zugeordnet',
            noOptionsText: 'Keine Daten',
            noResultsText: 'Keine Daten',
            openDirection: 'below',
            placeholder: 'Bitte wählen Sie Ihre Mitarbeiter aus',
            auto_load_root_options: false,
            loading: true,
            required: false,
            maxHeight: 440,
            limit: Infinity,
            showCount: false,
            showCountOf: 'ALL_CHILDREN',
            value: null,
            valueFormat: 'object',
            options: null,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                    //children: node.subOptions,
                }
            },
        };
    },
    mounted() {
        this.$root.$on('ok', (bvEvent, modalId) => {

            alert('hier');
            this.modalOk();
        })
    },
    methods: {
        action_delegation: function () {
            const self = this;

            this.bearbeitung_delegieren()
                .then(function (res) {
                    self.$swal({
                        title: 'Erfolg',
                        text: "Die Aufgabe wurde erfolgreich delegiert!",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });

                    window.location.href = self.signed_back_to_aufgaben_in;
                })
                .catch(error => console.log("Axios error: ", error));
        },
        bearbeitung_delegieren: function () {
            return axios.get('/emelder/meldungen/hauptverantwortung/' + this.meldung_id + '/delegieren_an/' + this.aenderung_durch_sonstige.id, {});
        },
        resetModalRedakteur: function () {
        },
        loadOptions({action, parentNode, callback}) {

            if (action === LOAD_ROOT_OPTIONS) {

                let url = this.signed_api_get_mitarbeiter_route_in;

                self = this;
                axios.get(url)
                    .then(function (res) {
                        self.options = res.data;
                        callback();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

        cancel: function () {

        },
        reloadAendererOptions: function () {

        },
        onSelect: function () {

        },
        onDeselect: function () {

        },
        onInput: function () {

        },
        onOpen: function () {

        },
    }
};
</script>
<style scoped>
</style>
