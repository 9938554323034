<template>
    <div>
        <MeldungsvorlageElementRight
            :vorlage_name_in="this.$parent.vorlage_name_in"
            vorlage_menu_title="Kopfzeile"
            :is_editable="true">
        </MeldungsvorlageElementRight>

        <div class="chat-body d-flex justify-content-center">
            <div class="col-md-11">
                <div class="mb-30">
                    <MeldungsvorlagenRightElementKopfzeile
                        input_in="TEST"
                        disabled_in="false"
                        :api_einstellungen_meldungsvorlagen_kopfzeile_show_in="this.$parent.api_einstellungen_meldungsvorlagen_kopfzeile_show_in"
                        :api_einstellungen_meldungsvorlagen_kopfzeile_store_in="this.$parent.api_einstellungen_meldungsvorlagen_kopfzeile_store_in">
                    </MeldungsvorlagenRightElementKopfzeile>
                </div>
                <br><br><br>
            </div>
        </div>
    </div>
</template>

<script>

    // ***********  Localization ***************
    import Lang from 'lang.js'

    // get the data source
    import translations from '../../../../../vue-translations.js';

    import MeldungsvorlageElementRight from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';
    import MeldungsvorlagenRightElementKopfzeile
        from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElementKopfzeile';

    //Bsp.: lang.get('auth.btn_login'));

    // ***********  Localization ***************

    export default {
        props: [],
        components: {MeldungsvorlageElementRight, MeldungsvorlagenRightElementKopfzeile},
        data() {
            return {
                lang: null,
            };
        },
        created() {
            var lang = new Lang();

            lang.setLocale(this.locale_in);

            if (lang.getLocale() === 'undefined') {
                lang.setLocale('de');
            }
            lang.setMessages(translations);
            this.lang = lang;
        },
        mounted() {
        },
        watch: {},
        computed: {},
        methods: {}
    };
</script>
<style scoped></style>
