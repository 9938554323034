<template>
    <div class="right_page">
        <MeldungsvorlageElementRight
            :vorlage_name_in="this.$parent.vorlage_name_in"
            vorlage_menu_title="Layout"
            :is_editable="this.is_editable">
        </MeldungsvorlageElementRight>

        <div v-if="is_editable" class="chat-body d-flex justify-content-center">
            <div class="col-md-11">
                <div v-if="zeilen && zeilen.length > 0">
                    <draggable
                        :list="zeilen"
                        :disabled="!enabled"
                        class="list-group"
                        ghost-class="ghost"
                        :move="draggElement"
                        @start="dragging = true"
                        @end="function(){dragStoreElement(); dragging = false}"
                    >
                        <div class="mb-30" v-for="(zeile,index) in zeilen">
                            <MeldungsvorlageElementRightLayoutZeile
                                :zeile="zeile"
                                :index="index"
                            ></MeldungsvorlageElementRightLayoutZeile>
                        </div>
                    </draggable>
                </div>
                <div v-else>
                    <div class="alert alert-info mb-15 mb-15 w-100">Keine Zeile vorhanden</div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <b-button variant="primary" @click="addZeile(0)">Neue Zeile hinzufügen
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <br><br><br>
            </div>
        </div>

        <div v-if="is_editable === false" class="chat-body d-flex justify-content-center">
            <div class="alert alert-warning py-30 px-30 emelder_einstellungen_warning_container">
                <i class="font-30 text-warning zmdi zmdi-alert-triangle"></i>
                Das Bearbeiten des Layouts ist nur möglich, wenn sich keine Meldung mit dieser Meldungsvorlage mehr in Bearbeitung befindet.
            </div>
        </div>

        <!--
        $spalte_baustein_titel = 1;
        $spalte_id_baustein_typ = 1;
        $spalte_col_span = 1;
        $spalte_key = 'bs-';
        $spalte_value = null;
        $spalte_options = null;
        $spalte_label = null;
        $spalte_description = null;
        $spalte_required = 0;
        $spalte_option1 = null;
        $spalte_option2 = null;
        $spalte_option3 = null;
        $spalte_option4 = null;
        $spalte_option5 = null;
        -->

        <b-modal id="edit_spalte_modal" title="Spalte bearbeiten" @ok.prevent="storeSpalte()">
            <div class="row mb-15">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="required">Baustein Typ</label>
                        <b-form-select v-model="edit_spalte.id_baustein_typ" :options="options_baustein_typen"
                                       @change="resetModal()"></b-form-select>
                    </div>
                    <!-- Auswahl 0: Leer -->
                    <div v-if="edit_spalte.id_baustein_typ == 0"></div>

                    <!-- Auswahl 1: Anzeigetext -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 1">
                        <div class="form-group mt-15">
                            <label class="required">Titel</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>
                        </div>
                    </div>
                    <!-- Auswahl 2:  Eingabefeld Text kurz -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 2">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 3:  Eingabefeld Text lang -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 3">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 4:  Eingabefeld Text nummerisch -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 4">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 5:  Eingabefeld Datum -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 5">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 6:  Eingabefeld Zeit -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 6">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 7:  Organisationselement -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 7">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                        <div class="form-group">
                            <label class="required">Eingabe erforderlich</label>
                            <b-form-select v-model="edit_spalte.required" :options="options_required"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 8:  Feld Datei-Upload -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 8">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                    </div>
                    <!-- Auswahl 9:  Globale Auswahlliste -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 9">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group">
                            <label class="required">Auswahlliste</label>
                            <b-form-select v-model="edit_spalte.option1"
                                           :options="options_auswahllisten"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 10:  Globale Auswahlskala -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 10">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group">
                            <label class="required">Auswahlskalen</label>
                            <b-form-select v-model="edit_spalte.option3"
                                           :options="options_auswahlskalen"></b-form-select>
                        </div>
                    </div>
                    <!-- Auswahl 11:  Globale Kontrollkästchen -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 11">
                        <div class="form-group mt-15">
                            <label class="required">Frage</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-15">
                                <label>Optionen</label>
                            </div>
                        </div>
                        <div
                            v-if="edit_spalte.kontrollkaestchen.optionen && edit_spalte.kontrollkaestchen.optionen.length > 0">
                            <draggable
                                :list="edit_spalte.kontrollkaestchen.optionen"
                                :disabled="!enabled"
                                class="list-group-drag"
                                ghost-class="ghost"
                                :move="draggElement"
                                @start="dragging = true"
                                @end="function(){dragStoreElement(); dragging = false}"
                            >
                                <div class="row mt-10" v-for="(option,index) in edit_spalte.kontrollkaestchen.optionen">
                                    <div class="col-md-8 d-inline-flex">
                                        <i class="zmdi zmdi-dehaze font-30 mt-5 mr-25 text-light-40"></i>
                                        <b-form-input
                                            v-model="option.bezeichnung"
                                            placeholder="Bitte eingeben"
                                            :id="'bezeichnung' + index"></b-form-input>
                                        <input type="hidden" :name="'option_'+index"
                                               :value="option.bezeichnung">
                                    </div>
                                    <div class="col-md-2 d-inline-flex text-center">
                                        <b-button @click="removeOption(index)" variant="danger" class="mr-10">X
                                        </b-button>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div v-else class="row">
                            <div class="col-md-12">
                                <b-alert show variant="info">Aktuell noch keine Optionen vorhanden</b-alert>
                            </div>
                        </div>
                        <b-button @click="newOption()" variant="primary" class="mt-15 mb-15">
                            Neue Option hinzufügen
                        </b-button>
                    </div>
                    <!-- Auswahl 12:  Optische Trennlinie -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 12">

                    </div>
                    <!-- Auswahl 13: Betreff -->
                    <div v-else-if="edit_spalte.id_baustein_typ == 13">
                        <div class="form-group mt-15">
                            <label class="required">Titel</label>
                            <b-form-input id="label" type="text" v-model="edit_spalte.label"></b-form-input>
                        </div>
                        <div class="form-group mt-15">
                            <label class="required">Beschreibung</label>
                            <b-form-textarea
                                id="description"
                                v-model="edit_spalte.description"
                                placeholder="Bitte eingeben"
                                rows="3"
                                max-rows="9"
                            ></b-form-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

// ***********  Localization ***************
import Lang from 'lang.js'

// get the data source
import translations from '../../../../../vue-translations.js';

import draggable from "vuedraggable";
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import MeldungsvorlageElementRight from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElement';
import MeldungsvorlageElementRightLayoutZeile
    from '../components/EMelderComponentEinstellungenMeldungsvorlagenRightElementLayoutZeile';

//Bsp.: lang.get('auth.btn_login'));

// ***********  Localization ***************

export default {
    props: [],
    components: {draggable, MeldungsvorlageElementRight, MeldungsvorlageElementRightLayoutZeile},
    data() {
        return {
            lang: null,
            enabled: true,
            meldung: {
                id: 12,
                vorlage_bezeichnung: 'CIRS-Meldung',
                ticket_id: 'e5d94538',
                link: '#',
                status_css: 'emelder_status_neu',
                status_txt_short: 'N',
                erstellt: '15.06.2020',
                frist: '15.07.2020',
            },
            zeilen: [],
            edit_spalte: {
                position: [],
                id_baustein_typ: 0,
                label: '',
                description: '',
                required: 0,
                option1: null,
                option2: null,
                option3: null,
                option4: null,
                option5: null,
                kontrollkaestchen: {
                    optionen: []
                }
            },
            options_baustein_typen: [{
                value: 0,
                text: 'Leer - bitte auswählen'
            }],
            options_auswahllisten: [{
                value: 0,
                text: 'Leer - bitte auswählen'
            }],
            options_auswahlskalen: [{
                value: 0,
                text: 'Leer - bitte auswählen'
            }],
            options_required: [{
                value: 0,
                text: 'Nicht erforderlich'
            }, {
                value: 1,
                text: 'Erforderlich'
            }],
            is_editable: this.is_editable,
        };
    },
    created() {
        var lang = new Lang();

        lang.setLocale(this.locale_in);

        if (lang.getLocale() === 'undefined') {
            lang.setLocale('de');
        }
        lang.setMessages(translations);
        this.lang = lang;

        this.get_editable();

        let self = this;
        this.$eventBus.$on('emelder_meldungsvorlage:add_Zeile', (position) => {
            self.addZeile(position);
        });

        this.$eventBus.$on('emelder_meldungsvorlage:remove_Zeile', (position) => {
            self.removeZeile(position);
        });

        this.$eventBus.$on('emelder_meldungsvorlage:add_Spalte', (position) => {
            self.addSpalte(position);
        });

        this.$eventBus.$on('emelder_meldungsvorlage:remove_Spalte', (position) => {
            self.removeSpalte(position);
        });

        this.$eventBus.$on('emelder_meldungsvorlage:edit_Spalte', (position) => {
            self.editSpalte(position);
        });

        this.$eventBus.$on('emelder_meldungsvorlage:store_Spalte', (position) => {
            self.storeZeilen();
        });

        this.getZeilen();
        this.get_baustein_typen();
        this.get_auswahllisten();
        this.get_auswahlskalen();
    },
    mounted() {
    },
    watch: {},
    computed: {},
    methods: {
        getZeilen() {
            let self = this;
            axios.get(this.$parent.api_einstellungen_meldungsvorlagen_layout_show_in, {})
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        storeZeilen() {
            let self = this;
            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_store_in, {
                zeilen: this.zeilen,
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    self.get_baustein_typen();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addZeile(position) {
            let self = this;
            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_add_zeile_in, {
                position: parseInt(position) + 1
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    self.$swal({
                        title: 'Erfolg',
                        text: "Zeile erfolgreich hinzugefügt",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        removeZeile(position) {
            let self = this;

            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_remove_zeile_in, {
                position: parseInt(position) + 1
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    //console.log(res.data.zeilen);
                    self.$swal({
                        title: 'Erfolg',
                        text: "Zeile erfolgreich entfernt",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    self.get_baustein_typen();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addSpalte(position) {
            let self = this;

            if (this.zeilen[parseInt(position.index_zeile)].spalten.length >= 4) {
                self.$swal({
                    title: 'Info',
                    text: "Sie können maximal 4 Spalten anlegen",
                    type: 'info',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000
                });
                return false;
            }

            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_add_spalte_in, {
                position_zeile: parseInt(position.index_zeile) + 1,
                position_spalte: parseInt(position.index_spalte) + 1,
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    self.$swal({
                        title: 'Erfolg',
                        text: "Spalte erfolgreich hinzugefügt",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        storeSpalte() {
            if (!this.edit_spalte.label) {
                this.$swal({
                    title: 'Hinweis',
                    text: 'Frage bzw. Titel sind jeweils ein Pflichtfeld und müssen ausgefüllt werden!',
                    type: 'warning',
                    toast: true,
                    position: 'center',
                    showConfirmButton: true,
                });
                return
            }

            if (!this.validate_kontrollkaestchen()) {
                this.$swal({
                    title: 'Fehler',
                    text: "Keine oder leere Option vorhanden",
                    type: 'error',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000
                });
                return
            }

            let self = this;
            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_store_spalte_in, {
                spalte: this.edit_spalte
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    self.$bvModal.hide('edit_spalte_modal');
                    self.$swal({
                        title: 'Erfolg',
                        text: "Spalte erfolgreich bearbeitet",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    self.get_baustein_typen();
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        editSpalte(position) {
            let self = this;
            self.get_baustein_typen();
            this.edit_spalte.position = position;
            this.edit_spalte.id_baustein_typ = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['id_baustein_typ'];
            this.edit_spalte.label = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['label'];
            this.edit_spalte.description = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['description'];
            this.edit_spalte.required = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['required'];
            this.edit_spalte.option1 = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['option1'];
            this.edit_spalte.option2 = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['option2'];
            this.edit_spalte.option3 = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['option3'];
            this.edit_spalte.option4 = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['option4'];
            this.edit_spalte.option5 = this.zeilen[this.edit_spalte.position.index_zeile]['spalten'][this.edit_spalte.position.index_spalte]['option5'];
            if (this.edit_spalte.id_baustein_typ == 11) {
                // Lade Kontrollkaestchen
                this.edit_spalte.kontrollkaestchen = [];
                if (this.edit_spalte.option2) {
                    this.get_kontrollkaestchen(this.edit_spalte.option2);
                }
            }
            this.$bvModal.show('edit_spalte_modal');
        },

        resetModal() {
            this.edit_spalte.label = '';
            this.edit_spalte.description = '';
            this.edit_spalte.required = 0;
            this.edit_spalte.kontrollkaestchen = {
                optionen: []
            };
        },
        get_baustein_typen() {
            let self = this;
            axios.get(this.$parent.api_einstellungen_meldungsvorlagen_layout_get_baustein_typen_in)
                .then(function (res) {
                    self.options_baustein_typen = res.data.baustein_typen;
                    console.log(res.data.subject_enabled)
                    if (!res.data.subject_enabled) {
                        self.options_baustein_typen.splice(12, 1)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_auswahllisten() {
            let self = this;
            axios.get(this.$parent.api_einstellungen_meldungsvorlagen_layout_get_auswahllisten_in)
                .then(function (res) {
                    self.options_auswahllisten = res.data.auswahllisten;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_auswahlskalen() {
            let self = this;
            axios.get(this.$parent.api_einstellungen_meldungsvorlagen_layout_get_auswahlskalen_in)
                .then(function (res) {
                    self.options_auswahlskalen = res.data.auswahlskalen;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        get_kontrollkaestchen(idKontrollkaestchen) {
            let self = this;

            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_get_kontrollkaestchen_in, {
                id_kontrollkaestchen: idKontrollkaestchen,
            })
                .then(function (res) {
                    self.edit_spalte.kontrollkaestchen = res.data.kontrollkaestchen;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        validate_kontrollkaestchen() {

            //check if Modal == kontrollkaestchen
            if (this.edit_spalte.id_baustein_typ == 11) {

                // Wenn keine Option vorhanden ist
                if (!this.edit_spalte.kontrollkaestchen.optionen.length)
                    return false;

                for (const option of this.edit_spalte.kontrollkaestchen.optionen) {
                    // Wenn nicht alle Optionstexte ausgefüllt
                    if (option.bezeichnung === null || option.bezeichnung == "")
                        // Prevent @ok event
                        return false;
                }
            }

            return true;
        },
        removeSpalte(position) {
            let self = this;
            axios.post(this.$parent.api_einstellungen_meldungsvorlagen_layout_remove_spalte_in, {
                position_zeile: parseInt(position.index_zeile) + 1,
                position_spalte: parseInt(position.index_spalte) + 1,
            })
                .then(function (res) {
                    self.zeilen = res.data.zeilen;
                    self.$swal({
                        title: 'Erfolg',
                        text: "Spalte erfolgreich entfernt",
                        type: 'success',
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    self.get_baustein_typen();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        newOption() {
            this.edit_spalte.kontrollkaestchen.optionen.push({
                bezeichnung: '',
            })
        },
        removeOption(index) {
            if (index > -1) {
                this.edit_spalte.kontrollkaestchen.optionen.splice(index, 1);
            }
        },
        draggElement: function (e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
            //this.triggerStore();
        },
        dragStoreElement: function () {
            let self = this;
            setTimeout(function () {
                self.storeZeilen();
            }, 200)
        },
        get_editable() {
            let self = this;
            axios.get(this.$parent.api_einstellungen_meldungsvorlagen_editable)
                .then(function (res) {
                    self.is_editable = res.data.editable;
                })
                .catch(function (error) {
                    console.log(error);
                });
            return false;
        },
    }
};
</script>
<style scoped>

.right_page {
    width: 100%;
}

.chat-body {
    height: calc(100vh - 458px) !important;
}

.emelder_einstellungen_card_zeile {
    width: 100% !important;
    border-left-color: #00acf0 !important;
    border-left-width: 15px !important;
}

</style>
