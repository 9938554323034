import Vue from 'vue'
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        search_dokman: {
            volltextsuche: "",
            org_gruppe: "",
            org_element: "",
            dokumentenart: "",
            status: "",
            redakteur: "",
            switch_eigene: false,
            switch_nur_hgb: false,
            switch_fuehrende: false,
            switch_urlaubsfenster: false,
            page: null,
        },
        search_aufgaben: {
            volltextsuche: "",
            aufgabe_typ: "",
            postfach: "",
            zeitraum_von: "",
            zeitraum_bis: "",
            id_status: "",
            id_user_von: "",
            switch_wiederkehrend: false,
            switch_delegiert: false,
            switch_ausgeblendet: false,
            page: null,
        },
        search_emelder: {
            volltextsuche: "",
            org_gruppe: "",
            org_element: "",
            meldungskategorie: "",
            meldungsvorlage: "",
            status: "",
            verantwortlicher: "",
            switch_eigene: "",
            page: null,
        }
    },
    mutations: {
        storeQueryDokman (state, queryArray) {
            localStorage.setItem('queryArrayDokman', JSON.stringify(queryArray));
            this.state.search_dokman = queryArray;
        },
        restoreQueryDokman(state){
            if (localStorage.getItem('queryArrayDokman')) {
                this.state.search_dokman = JSON.parse(localStorage.getItem('queryArrayDokman'));
            }
        },
        resetQueryDataDokman(state){
            this.state.search_dokman = {
                volltextsuche: "",
                org_gruppe: "",
                org_element: "",
                dokumentenart: "",
                status: "",
                redakteur: "",
                switch_eigene: false,
                switch_nur_hgb: false,
                switch_fuehrende: false,
                switch_urlaubsfenster: false,
                page: null,
            }
            localStorage.setItem('queryArrayDokman', JSON.stringify(this.state.search_dokman));
        },

        storeQueryAufgaben (state, queryArray) {
            localStorage.setItem('queryArrayAufgaben', JSON.stringify(queryArray));
            this.state.search_aufgaben = queryArray;
        },
        restoreQueryAufgaben(state){
            if (localStorage.getItem('queryArrayAufgaben')) {
                this.state.search_aufgaben = JSON.parse(localStorage.getItem('queryArrayAufgaben'));
            }
        },
        resetQueryDataAufgaben(state){
            this.state.search_aufgaben = {
                volltextsuche: "",
                aufgabe_typ: "",
                postfach: "",
                zeitraum_von: "",
                zeitraum_bis: "",
                id_status: "",
                id_user_von: "",
                switch_wiederkehrend: false,
                switch_delegiert: false,
                switch_ausgeblendet: false,
                page: null,
            }
            localStorage.setItem('queryArrayAufgaben', JSON.stringify(this.state.search_aufgaben));
        },

        storeQueryEMelder (state, queryArray) {
            localStorage.setItem('queryArrayEMelder', JSON.stringify(queryArray));
            this.state.search_emelder = queryArray;
        },
        restoreQueryDataEMelder(state){
            if (localStorage.getItem('queryArrayEMelder')) {
                this.state.search_emelder = JSON.parse(localStorage.getItem('queryArrayEMelder'));
            }
        },
        resetQueryDataEMelder(state){
            this.state.search_emelder = {
                volltextsuche: "",
                org_gruppe: "",
                org_element: "",
                meldungskategorie: "",
                meldungsvorlage: "",
                status: "",
                verantwortlicher: "",
                switch_eigene: "",
                page: null,
            }
            localStorage.setItem('queryArrayEMelder', JSON.stringify(this.state.search_emelder));
        }
    }
})
